import styled from "styled-components";

interface ContainerProps {
    fixLeftPanel?: boolean;
    showAvailableData?: boolean;
    conversationWidth: number;
}

interface LeftPanelProps {
    fixLeftPanel?: boolean;
}

interface CollapsiblePanelProps {
    fixLeftPanel?: boolean;
    showLeftPanel?: boolean;
}

interface UtilityBarProps {
    isDesktop?: boolean;
    colorBrightness?: string;
}

export const Container = styled.div<ContainerProps>`
    display: grid;
    grid-template-rows: 96px calc(100vh - 96px);
    height: 100%;
    background: ${props => props.theme["interface_background"]};
    
    @media (min-width: 1024px) { 
        grid-template-rows: 100vh;
        grid-template-columns: ${props => props.fixLeftPanel ? "324" : "60"}px ${props => props.conversationWidth}px ${props => props.showAvailableData ? " 368px" : ""};
    }
`;

export const UtilityBar = styled.div<UtilityBarProps>`
    position: relative;
    z-index: 13;
    height: 52px;
    border-bottom: 1px solid ${props => props.theme["left_pane_border"].light};
    display: flex;
    padding: 10px 12px;
    box-sizing: border-box;

    @media (min-width: 1024px) {
        height: auto;
        border-bottom: none;
        box-sizing: border-box;
        border-right: 1px solid ${props => props.theme["left_pane_border"].light};
        background: ${props => props.theme["interface_background"]};
        padding: 12px 10px 24px 10px;
        flex-direction: column;
    }

    .display-bottom {
        margin-top: auto;
    }

    .display-right {
        margin-left: auto;
    }
`;

export const LeftPanel = styled.div<LeftPanelProps>`
    height: 100%;
    
    border-right: 1px solid ${props => props.theme["left_pane_border"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"]};
    background: ${props => props.theme["interface_background"]};

    @media (min-width: 1024px) { 
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: ${props => props.fixLeftPanel ? "60px auto" : "60px"};
        background: none;
    }
`;

export const RightPanel = styled.div`
    
`;

export const SecondaryHeader = styled.div`
    @media (min-width: 1024px) {
        display: none;        
    }
`;

export const CollapsiblePanel = styled.div<CollapsiblePanelProps>`
    width: 300px;
    height: calc(100vh - 52px);
    position: absolute;
    z-index: 12;
    top: 52px;
    left: 0px;
    left: ${props => props.showLeftPanel ? "0" : "-100"}%;
    transition: left 0.5s;
    background: ${props => props.theme["interface_background"]};

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 564px;
    }

    @media (min-width: 1024px) {
        width: 263px;
        height: 100%;
        top: 0px;
        left: 0px;

        ${props => props.fixLeftPanel ? `
            position: relative;
        ` : `
            position: absolute;
            left: calc(60px + ${props.showLeftPanel ? "0" : "-100"}%);
            transition: left 0.5s;
            background: ${props.theme["interface_background"]};
        `
        }
    }
`;

export const PanelBack = styled.div`
    background: ${props => props.theme["ui_overlay"]};
    position: absolute;
    width: 100%;
    z-index: 11;
    height: calc(100vh - 52px);

    @media (min-width: 1024px) {
        display: none;
    }
`;

export const AvailableData = styled.div``;