import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div`
    padding: 40px 110px;
`;

export const GreetingEmoji = styled.div`
    ${props => getStyleString(props.theme["onboarding_greeting_text"])};
`;

export const Greeting = styled.div`
    margin-top: 16px;
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["onboarding_greeting_text"])};
`;

export const CopilotInfo = styled.div`
    margin-top: 16px;
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["onboarding_description"])};
`;

export const OnboardingDiv = styled.div`
    margin-top: 28px;
    display: grid;
    
    @media (min-width: 360px) and (max-width: 1023px) {
        grid-template-columns: 1fr;
    }

    @media (min-width: 768px) and (max-width: 1365px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1366px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    grid-gap: 20px;
`;

export const QuestionGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
`;

export const GroupTitle = styled.div`
    ${props => getStyleString(props.theme["eyebrow_text"])};
`;

export const Question = styled.div`
    border-radius: 8px;
    padding: 12px 20px;
    background: #F6F8FE;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    &:nth-child(3n + 1) ${GroupTitle} {
        color: #7E5BEF;
    }

    &:nth-child(3n + 2) ${GroupTitle} {
        color: #D240DD;
    }

    &:nth-child(3n) ${GroupTitle} {
        color: #12A9CE;
    }
`;

export const QuestionText = styled.div`
    margin-top: 20px;
    ${props => getStyleString(props.theme["onboarding_question"])};
    color: ${props => props.theme["text_02"].light};
`;

// export const OnboardingQuestionGroupTitle = styled.div`
//     ${(props) => `
//         ${getStyleString(props.theme["cardHeader"])};
//     `}
// `;

// export const OnboardingQuestionDiv = styled.div`
//     margin-top: 16px;
//     padding: 12px;
//     border-radius: 8px;
//     min-height: 72px;
//     box-sizing: border-box;
//     display: flex;
//     align-items: center;
//     cursor: pointer;

//     .question {
//         overflow: hidden;
//         max-height: 100%;
//     }

//     ${(props) => `
//         ${getStyleString(props.theme["ui"])};
//         background-color: ${props.theme["ui03"]};

//         svg path {
//             fill: ${props.theme["icon02"]}
//         }
//     `}

// `;
