import React, { useState, useEffect } from "react";
import ConversationPane from "./conversation-pane/ConversationPane";
import SidePane from "./side-panel/SidePanel";
import UtilityBar from "./utility-bar/UtilityBar";
import SecondaryHeader from "./secondary-header/SecondaryHeader";
import AvailableData from "./available-data/AvailableData";
import analyticsModule from "../../utils/analytics";
import { useAppSelector, useAppDispatch } from '../../stores/redux-hooks';
import { setShowAvailableData } from "../../reducers/App.reducer";

import { useTheme } from 'styled-components';

// styled components
import {
    Container as StyledContainer,
    LeftPanel as StyledLeftPanel,
    RightPanel as StyledRightPanel,
    UtilityBar as StyledUtilityBar,
    SecondaryHeader as StyledSecondaryHeader,
    CollapsiblePanel as StyledCollapsiblePanel,
    PanelBack as StyledPanelBack,
    AvailableData as StyledAvailableData
} from "./styled-components";

function MainLayout(props) {
    const [fixLeftPanel, setFixLeftPanel] = useState(true);
    const [showLeftPanel, setShowLeftPanel] = useState(false);
    const [deviceType, setDeviceType] = useState(props.deviceType);
    const showAvailableData = useAppSelector((state) => state.app.showAvailableData);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        // console.log("props.result: ", props.result);
        function addKeyBoardShortcuts(event) {
            if(event.key === "/" && (event.ctrlKey || event.metaKey)) {
                console.log("Show available data ", showAvailableData);
                if(props.isFullScreenAnsEnabled) {
                //   this.props.setAppMessage({
                //     message: "Exit expanded view to use shortcuts",
                //     type: "error",
                //   });
                } else {
                    dispatch(setShowAvailableData(!showAvailableData));
                    if(!showAvailableData) {
                        analyticsModule.trackEvent("Keyboard Shortcuts", {
                            "Type": "Available Data"
                        });
                    }
                }
              }
        }

        document.addEventListener("keydown", addKeyBoardShortcuts, false);

        return function () {
            document.removeEventListener("keydown", addKeyBoardShortcuts, false);
        };
    });

    useEffect(() => {
        setDeviceType(props.deviceType);
    }, [props.deviceType]) 

    function togglePanel() {
        if(fixLeftPanel) {
            setShowLeftPanel(false);
        }
        analyticsModule.trackEvent("Side Panel Toggled", {
            "Toggled": fixLeftPanel ? "Collapsed" : "Expanded"
        });
        setFixLeftPanel(!fixLeftPanel);
    }

    function leftPanelMouseOverHandler() {
        setShowLeftPanel(true);
    }

    function leftPanelMouseOutHandler() {
        setShowLeftPanel(false);
    }
    
    function hideLeftPanel() {
        setShowLeftPanel(false);
    }

    function toggleLeftPanelVisibility() {
        setShowLeftPanel(!showLeftPanel);
    }

    function availableDataCloseHandler() {
        dispatch(setShowAvailableData(false));
    }

    const isDesktop = props.deviceType === "desktop";
    const colorBrightness = theme.backgroundMeta?.interface_background?.colorBrightness; 
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const conversationWidth = vw - (fixLeftPanel ? 324 : 60) - (showAvailableData ? 368 : 0);

    return <StyledContainer fixLeftPanel={fixLeftPanel} showAvailableData={showAvailableData} conversationWidth={conversationWidth}>
        <StyledLeftPanel fixLeftPanel={fixLeftPanel} 
            onMouseOver={isDesktop ? leftPanelMouseOverHandler : null} 
            onMouseOut={isDesktop ? leftPanelMouseOutHandler : null}
        >   
            <StyledUtilityBar isDesktop={isDesktop} colorBrightness={colorBrightness}>
                <UtilityBar
                    profileSection={props.profileSection}
                    brandLogo={props.brandLogo}
                    copilotIcon={props.copilotIcon}
                    deviceType={deviceType}
                    
                ></UtilityBar>
            </StyledUtilityBar>
            <StyledSecondaryHeader>
                <SecondaryHeader onHamburgerClick={toggleLeftPanelVisibility} />
            </StyledSecondaryHeader>
            <StyledCollapsiblePanel fixLeftPanel={fixLeftPanel} showLeftPanel={showLeftPanel}>
                <div></div>
                <SidePane 
                    togglePanel={togglePanel}
                    isDesktop={props.deviceType === "desktop"}
                 />
            </StyledCollapsiblePanel>
            {showLeftPanel ? <StyledPanelBack onClick={hideLeftPanel}></StyledPanelBack> : null}
        </StyledLeftPanel>
        <StyledRightPanel>
            <ConversationPane transactionData={props.transactionList} />
        </StyledRightPanel>
        {showAvailableData ?
            <StyledAvailableData>
                <AvailableData onClose={availableDataCloseHandler} transactionData={props.transactionList}/>
            </StyledAvailableData>
            : null
        }
    </StyledContainer>
}

export default MainLayout;