import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from '../../../stores/redux-hooks';
import SessionItem from "../side-panel/sessions/session-list/session-item/SessionItem";
import websocketClient from "../../../utils/websocket-client";
import SessionService from "../../../services/session.service";
import {SOURCES, RESPONSE_TYPES} from "../../../constants";
// import SessionItem from "../../side-bar/sessions/session-list/session-item";
import AskBar from "./ask-bar/AskBar";
import Chat from "../../chat/Chat";
import { Modal, Dialogbox, ButtonType } from "@cuddle-dev/web-components";
import Assistance from "./assistance/Assistance";
import {generateUUID, getBrowserInfo, getWebsocketURL} from "../../../utils/utilityFunctions";
import {createNewSession, TEMP_SESSION_NAME} from "../../../reducers/chat-session-slice";
import ProfileIcon from "../../profile-icon/ProfileIcon";
import analyticsModule from "../../../utils/analytics";

//services
import CAPIService from "../../../services/configurationapi.service";
import ChatSessionService from "../../../services/chat-session.service";
import { set_message as setAppMessage } from "../../../reducers/App.reducer";
// styled components
import {
    Container as StyledContainer,
    Header as StyledHeader,
    Content as StyledContent,
    Chat as StyledChat,
    BottomOverlay as StyledBottomOverlay,
    EditDiv as StyledEditDiv,
    BottomLoader as StyledBottomLoader,
    DialogBoxWrapper as StyledDialogBoxWrapper,
    OverlayTopLoader as StyledOverlayTopLoader
} from "./styled-components";

import { ProfileIcon as StyledProfileIcon } from "../../chat/styled-components";
// import { setActiveSession } from "../../../reducers/chat-session-slice";
import { useTheme } from "styled-components";

const browserInfo = getBrowserInfo();

function ConversationPane(props) {
    const userID = useAppSelector((state) => state?.user?.details?.userID);
    const activeChatSession = useAppSelector((state) => state.chat.activeSession);
    const [chatData, setChatData] = useState(null);
    const [showChatLoader, setShowChatLoader] =  useState(false);
    const [onboardingSuggestedQuestions, setOnboardingSuggestedQuestions] = useState();
    const sessions = useAppSelector((state) => state.chat.sessions);
    const [sessionData, setSessionData] = useState(sessions[activeChatSession || 0]);
    const [disableAskBar, setDisableAskBar] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [tempSession, setTempSession] = useState("");
    const [dataSource, setDataSource] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [assistancesByConversations, setAssistancesByConversations] = useState({});
    const chatDivRef = useRef(null);
    const mainDivRef = useRef(null);
    const tempSessionData = useRef(undefined);
    const assistancesByChat = useRef({});
    const conversationInProgress = useRef([]);
    const assistanceTimers = useRef({});

    const theme = useTheme();
    const dispatch = useAppDispatch();

    useEffect(() => {
      // console.log("fetchOnboardingQuestions")
        fetchOnboardingQuestions();
    }, []);

    // useEffect(() => {
    //     console.log(chatData, "useEffect chatData edit")
    // }, [chatData]);

    useEffect(() => {
        if(activeChatSession) {
            if(tempSessionData?.current && activeChatSession === tempSessionData.current.conversationId) {
              const data = {...tempSessionData.current};
              tempSessionData.current = undefined;
              generateAskRequest(data.question, data.askSource, data.resolvedEntities, data.dataSource, data.conversationId);
            } else {
              setSessionData(sessions[activeChatSession]);
              if(activeChatSession !== TEMP_SESSION_NAME) {
                setChatData(null);
                setShowChatLoader(true);
                fetchConversations();
              } else {
                setChatData([]);
                setDataSource(SOURCES.data);
              }
            }
        }
    }, [activeChatSession]);

    function fetchOnboardingQuestions () {
        CAPIService.getOnboardingQuestions(
          { userID },
          (data) => {
            setOnboardingSuggestedQuestions(data);
          }
        );
    };

    function fetchConversations() {
        const sessionId = activeChatSession;
        let chatDataList = [];
        ChatSessionService.getLastNConversations(
        {
            user_id: userID,
            page_size: 10000,
        },
        {
            conversation_id: sessionId,
        }
        )
        .promise
        .then((response) => {
            let data = response && response.data;

            if (data) {
                data.sort((a, b) => a.created - b.created);
                let chatData = [];
                data.forEach((chat) => {
                  if(chat?.chatMessages) {
                    const responses = [];
                    let request;
                    let terminate;
                    chat.chatMessages.forEach(message => {
                      if(message.type === "REQUEST" && message.requestType === "CONVERSATION") {
                        if(!request) {
                          request = message;
                        }

                        if(message.userAction === "CANCELED" || message.userAction === "TIMEOUT") {
                          responses.push(message);
                        }
                      } else if(message.type === "RESPONSE") {
                        if(!message.responseTypes) {
                          responses.push(message);
                        } else if(!message.responseTypes.includes("ASSISTANCE")) {
                          responses.push(message);
                          if(message.responseTypes.includes("TERMINATE")) {
                            terminate = terminate || [];
                            terminate.push({
                              created: message?.created,
                              messageId: message.messageId
                            });
                          }
                        }
                      }
                    });

                    chat.request = request;
                    chat.responses = responses;
                    chat.terminate = terminate;
                  }
                  console.log(chat, "handle terminate")
                
                  chatData.push(chat);
                });

                console.log(chatData, "chatData chatData");

                chatData.forEach((data, index) => {
                    if(conversationInProgress.current.indexOf(data.request.chatId) === -1) {
                      data.isHistory = true;
                    } else {
                      data.isLoading = true;
                    }

                    if(!data.terminate) {
                      data.terminate = [{
                        created: data?.request?.created
                      }]
                    }
                });

                chatDataList = [...chatDataList, ...chatData];
                setChatData(chatDataList);
                setShowChatLoader(false);
                console.log(chatDataList, "chatDataList")
            }

            if(chatDataList.length) {
              // console.log(chatDataList[chatDataList.length - 1].request, "SOURCES[chatDataList[chatDataList.length - 1].request");
              setDataSource(chatDataList[chatDataList.length - 1].request.searchSpace)
            } else {
              setDataSource(SOURCES.data);
            }
        })
        .catch((error) => {
            setChatData(null);
            setShowChatLoader(false);
        });

    }

    function onAddSessionComplete(question, askSource, resolvedEntities, dataSource, isSuccessful, data) {
      if(isSuccessful) {
        tempSessionData.current = {
          question, 
          askSource, 
          resolvedEntities, 
          dataSource, 
          conversationId: data.conversationId
        };
        // generateAskRequest(question, askSource, resolvedEntities, dataSource, data.conversationId);
      }
    }

    function onAsk(question, askSource, resolvedEntities, dataSource) {
      console.log("onAsk: ", question, askSource, resolvedEntities, dataSource);
      if(question) {
        if(activeChatSession === TEMP_SESSION_NAME) {
          dispatch(createNewSession(onAddSessionComplete.bind(this, question, askSource, resolvedEntities, dataSource)));
        } else {
          generateAskRequest(question, askSource, resolvedEntities, dataSource, activeChatSession);
        }
      }
    }

    function generateAskRequest(question, askSource, resolvedEntities, dataSource, conversationId) {
      createAskRequest(question, resolvedEntities, askSource, conversationId);
      setCurrentQuestion(question);
      setDisableAskBar(true);
    }

    function createAskRequest(question, resolvedEntities, askSource, conversationId) {
        if(!chatData) {
          setChatData([]);
        }

        let request = createAskRequestJson(question, resolvedEntities, conversationId);
        
        chatData.push({
          request,
          responses: [],
          isLoading: true
        });
        
        setChatData(chatData);


        scrollToBottom();
        setShowChatLoader(false);
        startWebsocketConnection(chatData[chatData.length - 1]);
      };

    function scrollToBottom() {
      // if(mainDivRef.current) {
      //   console.log(mainDivRef.current.clientHeight, "dewdewdewdew mainDivRef", mainDivRef.current.clientHeight + chatDivRef.current.offsetHeight, chatDivRef.current.scrollHeight)
      //   mainDivRef.current.style.height = mainDivRef.current.clientHeight + chatDivRef.current.offsetHeight + 'px';
      // }

      if (chatDivRef.current) {
        if (chatDivRef) {
          setTimeout(() => {
            const scrollHeight = chatDivRef.current.scrollHeight;
            const height = chatDivRef.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            chatDivRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            // console.log(maxScrollTop, chatDivRef.current.offsetHeight, chatDivRef.current.clientHeight, chatDivRef.current.offsetHeight, chatDivRef.current.scrollHeight, "dewdewdewdew")
          }, 1000);
        }
      }
    }
    
    function makeWebsocketCall(websocketClientObj) {
        console.log(chatData, "onEditQuestionSubmit");
        if(chatData) {
          let request = chatData[chatData.length - 1].request;
          console.log(request, "request makeWebsocketCall");
          if (websocketClientObj && websocketClientObj.client) {
            websocketClientObj.send(request);
            conversationInProgress.current.push(request.chatId);
            console.log("conversationInProgress.current: ", conversationInProgress.current);
          } else {
            console.log("[makeWebsocketCall] websocket was disconnected.");
          }
        }

    };

    function startWebsocketConnection (chatObj) {
        let currentProject = SessionService.getItem("currentProject");
        let queryParams = {};

        if (currentProject) {
          queryParams["projectid"] = JSON.parse(currentProject).id;
        }

        websocketClient(
          {
            id: "QUESTION",
            queryParams,
            onMessage: websocketMessageHandler,
            onError: websocketErrorHandler.bind(chatObj.request.conversationId, chatObj.request.chatId),
            onDisconnect: (data) => {
              console.log("[startWebsocketConnection - onDisconnect] data: ", data);
            },
            path: `${getWebsocketURL()}/conversation/chat`,
          },
          (websocketClient) => {
            makeWebsocketCall(websocketClient);
          }
        );
      };

      function timeoutHandler(data) {
        const assistanceRequest = {
          "parentMessageId": data.messageId,
          "version": "1",
          "chatId": data.chatId,
          "conversationId": data.conversationId,
          "userInfo": data.userInfo,
          "type": "REQUEST",
          "requestType":"CONVERSATION",
          "userAction":"TIMEOUT",
          "messages": {
            "error": [
              {
                "code": "499",
                "message": "Assistance canceled by user",
                "displayMessage": "There was an error in rendering this response as we did not get all the required inputs from you."
              }
            ]
          }
        };

        createAssistanceWebsocket(assistanceRequest, data.conversationId, "TIMEOUT");
      }

      function setAssistanceTimeout(data) {
        assistanceTimers.current[data.conversationId] = setTimeout(function() {
          timeoutHandler(data);
        }, 60 * 5 * 1000);
      }
    
      function websocketMessageHandler (data, websocketClientObj, from){
        console.log("from", chatData, from);
        if(activeChatSession === data.conversationId) {
          console.log("[websocketMessageHandler]", data);
          if(data.type === "RESPONSE") {  
              if(data.responseTypes?.includes(RESPONSE_TYPES.assistance)) {
                const assistanceData = data.data?.[RESPONSE_TYPES.assistance];

                if(assistanceData) {
                  setAssistancesByConversations(assistancesByConversations => {
                    let newAssistanceState;
                    if(!assistancesByConversations[data.conversationId]) {
                      setAssistanceTimeout(data);

                      newAssistanceState = {
                        assistances: [data]
                      };
                    } else {
                      newAssistanceState = {...assistancesByConversations[data.conversationId]};
                      newAssistanceState.assistances.push(data);
                    }

                    const newState = {...assistancesByConversations};
                    newState[data.conversationId] = newAssistanceState;

                    return newState;
                  });
                  
                  if(!assistancesByChat.current[data.chatId]) {
                    assistancesByChat.current[data.chatId] = [data.messageId];
                  } else {
                    assistancesByChat.current[data.chatId].push(data.messageId);
                  }
                }

                setChatData(chatData => {
                  const newChatData = [...chatData];
                  newChatData[newChatData.length - 1].isHistory = false;
                  newChatData[newChatData.length - 1].nextLoading = true;
                  return newChatData;
                });
              } else {
                setChatData(chatData => {
                  const newChatData = [...chatData];
                  newChatData[newChatData.length - 1].isHistory = false;
                  newChatData[newChatData.length - 1].nextLoading = true;
                  newChatData[newChatData.length - 1].responses.push(data);
                  
                  if(data.responseTypes?.includes("TERMINATE")) { 
                    if (websocketClientObj && websocketClientObj.client) {
                      if(websocketClientObj.id.includes("QUESTION") && !assistancesByChat.current[data.chatId]?.length) {
                        newChatData[newChatData.length - 1].isLoading = false;
                        const index = conversationInProgress.current.indexOf(data.chatId);

                        if (index > -1) { 
                          conversationInProgress.current.splice(index, 1); 
                        }
                      } else if(websocketClientObj.id.includes("TERMINATE") && assistancesByChat.current[data.chatId]) {
                        // console.log("===== assistancesByChat.current: ", assistancesByChat.current, data.chatId);
                        if(assistancesByChat.current[data.chatId].length === 1) {
                          delete assistancesByChat.current[data.chatId];
                          newChatData[newChatData.length - 1].isLoading = false;
                          const index = conversationInProgress.current.indexOf(data.chatId);

                          if (index > -1) { 
                            conversationInProgress.current.splice(0, 1); 
                          }
                        } else {
                          assistancesByChat.current[data.chatId].splice(0, 1);
                        }
                      }
                      websocketClientObj.client.close();
                    }

                    newChatData[newChatData.length - 1].terminate = newChatData[newChatData.length - 1].terminate || [];
                    newChatData[newChatData.length - 1].terminate.push({
                      created: data?.created,
                      messageId: data.messageId
                    });
                  }
                  return newChatData;
                });
              } 
              // else {
              //   setChatData(chatData => {
              //     const newChatData = [...chatData];
              //     newChatData[newChatData.length - 1].isHistory = false;
              //     newChatData[newChatData.length - 1].nextLoading = true;
              //     return newChatData;
              //   });
              // }
          }

          if (data && data.responseTypes && data.responseTypes.includes("TERMINATE")) {
            setDisableAskBar(false);
          }
        } else {
          if(data.type === "RESPONSE" && data.responseTypes?.includes("TERMINATE")) {
            if (websocketClientObj && websocketClientObj.client) {
              if(websocketClientObj.id.includes("QUESTION") && !assistancesByChat.current[data.chatId]?.length) {
                const index = conversationInProgress.current.indexOf(data.chatId);

                if (index > -1) { 
                  conversationInProgress.current.splice(index, 1); 
                }
              } else if(websocketClientObj.id.includes("TERMINATE")) {
                if(assistancesByChat.current[data.chatId].length === 1) {
                  delete assistancesByChat.current[data.chatId];
                  const index = conversationInProgress.current.indexOf(data.chatId);

                  if (index > -1) { 
                    conversationInProgress.current.splice(0, 1); 
                  }
                } else {
                  assistancesByChat.current[data.chatId].splice(0, 1);
                }
              }
              websocketClientObj.client.close();
            }
          }
        }
    };
    
    function websocketErrorHandler(conversationId, chatId, event, websocketClientObj) {
      setDisableAskBar(false);
      if (websocketClientObj && websocketClientObj.client) {
        websocketClientObj.client.close();
      }
      // makeWebsocketCall(websocketClientObj);
      // websocketMessageHandler({
      //   conversationId,
      //   chatId,
      //   "type": "RESPONSE",
      //   "responseTypes": [
      //       "TERMINATE"
      //   ],
      //   "data": {},
      //   "visible": true
      // }, websocketClientObj);
    };
    
    function createAskRequestJson (questionText, resolvedEntities, conversationId) {
      let chatId = generateUUID();
      let autoSuggestTokens;

      if (resolvedEntities && resolvedEntities.length) {
          autoSuggestTokens = {
            data: resolvedEntities.map((re) => re.originalData),
            question: questionText,
          };
        }

      let request = {
        chatId,
        conversationId,
        data: {
          "CHAT": {
              question: {
                  query: questionText,
                  userId: userID,
                  location: "",
                  deviceType: `Desktop-${browserInfo.name}-${browserInfo.version}`,
                  autoSuggestTokens
                },
          }
        },
        userInfo: {
          profile: "ANALYST",
          userId: userID,
        },
        "type":  "REQUEST",
        "searchSpace": dataSource,
        "requestType": "CONVERSATION",
        "userAction":  "CHAT",
        created: new Date().getTime()
      };
  
      return request;
  
    }

    // function dataSourceChangeHandler(source) {
    //     setDataSource(source);
    //     dispatch(setAppMessage({
    //       type: "success",
    //       message: "Source has been successfully changed."
    //     }));

    //   console.log("[dataSourceChangeHandler] source: ", source);
    // }
      
    function onDeleteChatClick(request) {
      console.log(request, "request onDeleteChatClick")
      setShowModal(true);
      setModalType("delete");
      setSelectedConversation(request);
      analyticsModule.trackEvent("Question Updated", {
        "Conversation ID": request.conversationId,
        "Chat ID": request.chatId,
        "Type": "Delete"
      });
    }

    function closeModal(){
      setShowModal(false);
      setModalType("");
      setSelectedConversation(null);
    }

    function deleteChat(){
      ChatSessionService.deleteChat({
        user_id: userID,
        chat_id: selectedConversation.request.chatId,
        conversation_id: selectedConversation.request.conversationId
      })
      .promise
      .then(() => {
        // this.props.setAppMessage({
        //   message: "Question deleted successfully",
        //   type: "success",
        // });
        // let deleteIndex = chatData.filter(data => data.request.chatId !== selectedConversation.request.chatId);

        let searchIndex;
        chatData.forEach((data, index) => {
            if(data.request.chatId === selectedConversation?.request?.chatId) {
              searchIndex = index;
            }
        });

        console.log(searchIndex, "searchIndex")
        chatData.splice(searchIndex, chatData.length);
        setChatData(chatData);
        // setChatData(chatData.filter(data => data.request.chatId !== selectedConversation.request.chatId));
        // fetchConversations();
        closeModal();
        // Question deleted successfully.
      })
      .catch(error => {
        // this.props.setAppMessage({
        //   message: "There was an error deleting the question.",
        //   type: "error",
        // });
  
        closeModal();
        //There was an error deleting the question.
      });
    }
    

    function onDeleteMessageClick(request) {
      console.log(request, "request onDeleteMessageClick")
      setShowModal(true);
      setModalType("delete-message");
      setSelectedConversation(request);
      analyticsModule.trackEvent("Answer Removed", {
        "Conversation ID": request.conversationId,
        "Chat ID": request.chatId,
        "Message ID": request.messageId
      });
    }

    function onMessageDelete(){
      ChatSessionService.deleteMessage({
        user_id: userID,
        chat_id: selectedConversation.chatId,
        conversation_id: selectedConversation.conversationId,
        message_id: selectedConversation.messageId
      })
      .promise
      .then(() => {
        // this.props.setAppMessage({
        //   message: "Question deleted successfully",
        //   type: "success",
        // });
  
        fetchConversations();
        closeModal();
        // Question deleted successfully.
      })
      .catch(error => {
        // this.props.setAppMessage({
        //   message: "There was an error deleting the question.",
        //   type: "error",
        // });
  
        closeModal();
        //There was an error deleting the question.
      });
    }

    function sourceClickHandler(data) {
      console.log("[sourceClickHandler] data: ", data);
      const source = data?.value;
      if(dataSource !== source) {
        setDataSource(source);
        dispatch(setAppMessage({
          type: "success",
          message: "Source has been successfully changed."
        }));

        analyticsModule.trackEvent("Answer Source Selected", {
          "Answer From": data.label
        });
      }
    }
    
    function fetchEditQuestionUI() {
      const question = selectedConversation?.request?.data?.CHAT?.question?.query;

      return <StyledEditDiv>
          <StyledProfileIcon className="profile-icon">
            <ProfileIcon />
          </StyledProfileIcon>
         <AskBar onAsk={onEditQuestionSubmit} 
            hideSource={true}
            submitUsingButton={true}
            question={question}
            bottomDropdown={true}
            disabled={false}
            cancel={closeModal}
        />
      </StyledEditDiv>
    }

    function onEditClick(request) {
      setShowModal(true);
      setModalType("edit");
      setSelectedConversation(request);
    }

    function onEditQuestionSubmit(question) {
      let newRequest =  {...selectedConversation.request};
      let editIndex;
      newRequest.userAction = "CHAT_EDIT";
      newRequest.userInfo = {
        profile: "ANALYST",
        userId: userID,
      };

      newRequest.data.CHAT.question.query = question;
      chatData.forEach((data, index) => {
        if(newRequest.chatId === data.request.chatId) {
          editIndex = index;
          chatData[index] = {
            chatId: data.request.chatId,
            conversationId: data.request.conversationId,
            request: newRequest,
            chatMessages: null,
            responses: [],
            isLoading: true
          }
        }
      });

      setChatData(chatData);
      closeModal();
      startWebsocketConnection(chatData[editIndex]);
      analyticsModule.trackEvent("Question Updated", {
        "Conversation ID": chatData[editIndex].conversationId,
        "Chat ID": chatData[editIndex].chatId,
        "Type": "Edit"
      });
    }

    function removeLatestAssistance(conversationId) {
      setAssistancesByConversations(assistancesByConversations => {
        const newAssistancesByConversations = {...assistancesByConversations};
        const newData = [...newAssistancesByConversations[conversationId].assistances];
        
        clearTimeout(assistanceTimers.current[conversationId]);
        assistanceTimers.current[conversationId] = null;

        if(newData.length > 1) {
          newData.splice(0, 1);
          newAssistancesByConversations[conversationId].assistances = newData;
          setAssistanceTimeout(newData[0]);
        } else {
          newAssistancesByConversations[conversationId] = null;
        }

        return newAssistancesByConversations;
      });
    }

    function removeAllAssistances(conversationId) {
      setAssistancesByConversations(assistancesByConversations => {
        const newAssistancesByConversations = {...assistancesByConversations};
        
        clearTimeout(assistanceTimers.current[conversationId]);
        assistanceTimers.current[conversationId] = null;

        newAssistancesByConversations[conversationId] = null;

        return newAssistancesByConversations;
      });
    }

    function createAssistanceWebsocket(data, conversationId, type?) {
      let currentProject = SessionService.getItem("currentProject");
      let queryParams = {};

      if (currentProject) {
        queryParams["projectid"] = JSON.parse(currentProject).id;
      }

      if(type === "CANCEL" || type === "TIMEOUT") {
        setChatData(chatData => {
          const newChatData = [...chatData];
          newChatData.forEach((chatDatum, index) => {
            if(data.chatId === chatDatum.request.chatId) {
              const newResponses = [...chatDatum.responses];
              newResponses.push(data);
              newChatData[index].responses = newResponses;
            }
          });
          return newChatData;
        });
      }
      
      websocketClient(
        {
          id: `TERMINATE_${new Date().getTime()}`,
          queryParams,
          onMessage: websocketMessageHandler,
          onError: websocketErrorHandler,
          onDisconnect: (data) => {
            console.log("[startWebsocketConnection - onDisconnect] data: ", data);
          },
          path: `${getWebsocketURL()}/conversation/chat`,
        },
        (websocketClient) => {
          if(type === "CANCEL" || type === "TIMEOUT") {
            removeAllAssistances(conversationId);
          } else {
            removeLatestAssistance(conversationId);
          }
          websocketClient.send(data);
        }
      );
    }

    function assistanceSubmitHandler(data) {
      // console.log("assistanceSubmitHandler data: ", data);
      createAssistanceWebsocket(data, activeChatSession);
    }

    function assistanceCancelHandler(data) {
      // console.log("assistanceCancelHandler data: ", data);
      createAssistanceWebsocket(data, activeChatSession, "CANCEL");
    }

    const assistanceData = activeChatSession && assistancesByConversations?.[activeChatSession]?.assistances[0];

    return <StyledContainer>
        <StyledHeader>
            {sessionData ? <SessionItem
                  key={sessionData.chatSessionId}
                  data={sessions[activeChatSession]}
                  isactive={true}
                  hideDelete={true}
                  isbackgroundlight={true}
                  fromComponent={"Conversation Header"}
                  fullWidth={false}
                /> : null}
        </StyledHeader>
        <StyledContent id="conversation_pane_content" >
            <StyledChat ref={chatDivRef} withLoader={chatData?.[chatData.length - 1]?.isLoading}>
              {/* height when only onboarding questions are present */}
              <div  ref={mainDivRef} style={(chatData && !chatData.length) ? {height: "100%"} : {}}>
                {
                  chatData ? <Chat
                    chatData={chatData}
                    userID={userID}
                    // currencyFormat={this.state.currencyFormat}
                    onAsk={onAsk}
                    onBoardingQuestion={onboardingSuggestedQuestions}
                    // next={this.fetchConversations}
                    // hasMore={true}
                    onEditClick={onEditClick}
                    onMessageDelete={onDeleteMessageClick}
                    deleteChat={onDeleteChatClick}
                    transactionData={props.transactionData}
                    scrollToBottom={scrollToBottom}
                  ></Chat> : null
                }
              </div>
            </StyledChat>
            {activeChatSession ?
              <StyledBottomOverlay key={activeChatSession}>
                {(assistanceData || chatData?.[chatData.length - 1]?.isLoading) ? <StyledOverlayTopLoader /> : null}
                {assistanceData ?
                  <Assistance key={assistanceData.messageId}
                    data={assistanceData} 
                    assistanceSubmitHandler={assistanceSubmitHandler} 
                    assistanceCancelHandler={assistanceCancelHandler} 
                  /> : (
                    chatData?.[chatData.length - 1]?.isLoading ?
                    <StyledBottomLoader>{
                      chatData[chatData.length - 1].nextLoading ? 
                      "⛏ Mining through your data..." :
                      "🧠 Understanding your question..."
                    }</StyledBottomLoader>
                    : <AskBar onAsk={onAsk} 
                      hideSource={false}
                      submitUsingButton={false}
                      question={""}
                      bottomDropdown={false}
                      disabled={disableAskBar || !dataSource}
                      selectedDataSource={dataSource}
                      sourceClickHandler={sourceClickHandler}
                    />
                  )
                }
              </StyledBottomOverlay>
              : null
            }
        </StyledContent>
        {
          showModal ? <>
            {
              modalType === "delete" ? (
                <Modal parentId="conversation_pane_content"
                  content={<StyledDialogBoxWrapper>
                    <Dialogbox 
                      onClose={closeModal} 
                      header={{
                        title: "Delete Question"
                      }}
                      body="This action will permanently delete the question, including everything that follows the question."
                      type="negative"
                      footer={ {
                        rightActionButtons: [
                          {
                            buttonType: ButtonType.tertiary,
                            text: "No, cancel",
                            onClick: closeModal,
                          },
                          {
                            buttonType: ButtonType.negative,
                            text: "Delete",
                            onClick: deleteChat,
                          },
                        ],
                      }}
                    /></StyledDialogBoxWrapper>
                  }
                  closeModal={closeModal}
              />

              ) : null
            }  
            {
              modalType === "delete-message" ? (
                <Modal parentId="conversation_pane_content"
                  content={<StyledDialogBoxWrapper>
                    <Dialogbox 
                      onClose={closeModal} 
                      header={{
                        title: "Remove Answer"
                      }}
                      body="Are you sure you want to want to remove this answer from response?"
                      type="negative"
                      footer={ {
                        rightActionButtons: [
                          {
                            buttonType: ButtonType.tertiary,
                            text: "No, cancel",
                            onClick: closeModal,
                          },
                          {
                            buttonType: ButtonType.negative,
                            text: "Remove",
                            onClick: onMessageDelete,
                          },
                        ],
                      }}
                    />
                  </StyledDialogBoxWrapper>
                }
                closeModal={closeModal}
              />

              ) : null
            }  
            {
              modalType === "edit" ? (
                <Modal className="edit-question-modal" parentId="conversation_pane_content"
                  content={fetchEditQuestionUI()}
                  closeModal={closeModal}
              />

              ) : null
            }  
          </> : null
        }
    </StyledContainer>
}

export default ConversationPane;