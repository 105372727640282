import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../stores/redux-hooks';

// styled components
import { 
  Container as StyledContainer,
  GreetingEmoji as StyledGreetingEmoji,
  Greeting as StyledGreeting,
  CopilotInfo as StyledCopilotInfo,
  OnboardingDiv as StyledOnboardingDiv,
  QuestionGroup as StyledQuestionGroup,
  Question as StyledQuestion,
  GroupTitle as StyledGroupTitle,
  QuestionText as StyledQuestionText
} from "./styled-components";

function selectFew(data, count) {
  if(data?.length) {
    if(data.length > count) {
      const indexes = [];

      while(indexes.length < count) {
        const randomNum = Math.floor(Math.random() * data.length);
        if(indexes.indexOf(randomNum) === -1) {
          indexes.push(randomNum);
        }
      }

      return indexes.map(index => data[index]);
    }

    return data;
  }

  return [];
}

function getData(data) {
  const groups = selectFew(data, 3);
  const questions = {};
  const newData = [];

  groups.forEach(group => {
    const selectedQuestions = selectFew(group.onBoardingQuestions, 2);
    questions[group.id] = selectedQuestions.map(question => {
      return {
        groupName: group.name,
        groupId: group.id,
        question
      };
    });
  });

  for(let i = 0; i < 2; i++) {
    groups.forEach(group => {
      if(questions[group.id][i]) {
        newData.push(questions[group.id][i]);
      }
    });
  }

  return newData;
}

function Onboarding(props) {
    // const [onBoardingQuestions, setOnBoardingQuestions] = useState(props.data);
    const [data, setData] = useState(getData(props.data));
    const userFirstName = useAppSelector((state) => state.user.details.firstName);
    const projectInfo = useAppSelector((state) => state.currProject.projectInfo);
    

    useEffect(() => {
      setData(getData(props.data));
    }, [props.data]);

    const onQuestionClick = (question, topic) => {
      if(props.onAsk) {
        props.onAsk(question, {
          from: "Onboarding",
          topic,
        });
      }
    }

    // console.log("Onboarding", data, "data", props.data);
    
    return (
      <StyledContainer>
          <StyledGreetingEmoji>👋</StyledGreetingEmoji>
          <StyledGreeting>{`Hello ${userFirstName}`}</StyledGreeting>
          <StyledCopilotInfo>{`I’m ${projectInfo?.name || "Crux" }, your analytics copilot. I can analyze data from various data sources available and answer your questions in plain English. Here are a few examples to get started:`}</StyledCopilotInfo>
          <StyledOnboardingDiv className='ask-onboarding'>
            {data.map(datum => {
              return (
                <StyledQuestion key={`${datum.groupId}-${datum.question.id}`} 
                  onClick={onQuestionClick.bind(this, datum.question?.question, datum.groupName)}
                >
                  <StyledGroupTitle>{datum.groupName.toUpperCase()}</StyledGroupTitle>
                  <StyledQuestionText className='question' title={datum.question.question}>
                    {datum.question.question}
                  </StyledQuestionText>
                </StyledQuestion>
              )
            })}
          </StyledOnboardingDiv>
      </StyledContainer>
      
    );
}

export default Onboarding;