import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import MainLayout from "./main-layout/MainLayout";

import "./Main.scss";

import httpRequestService from "../utils/httpRequest.js";
import AppContext from "../AppContext.js";
// import {SVGIcon} from "@cuddle-dev/web-components";
// import DialogBox from "@cuddle-dev/test-web-ui-lib/dist/dialog-box";
// import analyticsModule from "@cuddle-dev/test-common-web-utils/dist/utils/analytics";
// import ButtonComponent from "@cuddle-dev/test-web-ui-lib/dist/buttonComponent";

import {
  getConfigurationapiURL,
  getCookie,
  mobileAndTabletcheck,
  getBrowserInfo,
  fetchImage
} from "../utils/utilityFunctions.js";

// import { setCookie } from "@cuddle-dev/test-common-web-utils/dist/utils/utilityFunctions";
import _ from "lodash";


//services
import SessionService from "../services/session.service.js";
import ThemeService from "../services/theme.service";
import DocumentService from "../services/document.service";
import DataDiscoveryService from "../services/dataDiscovery.service";

import {
  show as showLoaderFn,
  hide as hideLoaderFn,
} from "../reducers/loader-slice";

import { set_message as setAppMessage } from "../reducers/App.reducer.js";
import { set_project_list as setProjectList } from "../reducers/Project.reducer.js";
import { set_project as setCurrentProject, set_project_info as setProjectInfo } from "../reducers/CurrProj.reducer.js";
import { set_settings as setSystemSettings } from "../reducers/settings-slice";

//style-components
// import {
//   MainPage as StyledMainPage
// } from "./styled-components.js";

//constants
import {
  USER_LOGIN_RESPONSE,
  MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY,
  DEVICE_TYPES_BY_WIDTH
} from "../constants/index.js";

import AppLoader from "../components/app-loader/AppLoader.component.js";
import configurationapiService from "../services/configurationapi.service.js";


import { ReactComponent as logoutIcon} from "../images/logout-20.svg"; 
import copilotLogo from "../images/copilot-logo.svg";

//urls
const configurationapiURL = getConfigurationapiURL();

// let organizationAPI = new dataAPI();
const browserInfo = getBrowserInfo();
const actionMenuOptions = [
  // {
  //   label: "Change time zone",
  //   value: "chngTimezone",
  //   icon: Icons.globe,
  // },
  // {
  //   label: "Change password",
  //   value: "chngPassword",
  //   icon: Icons.key,
  // }
];

const logoutOption = [
  {
    label: "Logout",
    value: "logout",
    icon: logoutIcon
  },
];

// const dispatch = useDispatch();

class Main extends Component {
  constructor(props) {
    super(props);
    let userDetails = this.getUserDetails();
    let userID = props.userDetails.userID;
    this.email = userDetails.email;

    this.mainPageRef = React.createRef();
    this.state = {
      settings: undefined,
      userID: userID,
      expandQuestionAnnotation: false,
      openPassModal: false,
      deviceType: "desktop",
      projects: this.props.userProjects || null,
      loading: true,
      clearAsk: false
    };

    this._SessionService = SessionService;
    // this._OrganizationUserService = OrganizationUserService;
    this.isMobileOrTablet = mobileAndTabletcheck();

    httpRequestService.setTimeoutCallback(this.timeoutEvent);
    // document.addEventListener('keydown', this.keyDownHandler);
    console.log("props.userDetails: ", props.userDetails);
  }

  async componentDidMount() {
    console.log("[Main.js] componentDidMount.");
    this.setWindowType();
    window.addEventListener("resize", this.resize);
    this.getSettingInfo();
    this.fetchTransactionList();
    this.fetchSystemSettings();
    this.fetchProjectInfo();
    if((!this.props.brandLogo && !this.props.copilotIcon)) {
      this.setProjectTheme();
    } else {
      this.setState({
        brandLogo: this.props.brandLogo,
        copilotIcon: this.props.copilotIcon
      })
    }

    // this.fetchCCAccess();

    let organizationId = getCookie("organisation");

    try {
      let organisationConfig = (
        await this.fetchOrganisationSettings(organizationId)
      ).data.organization;
      let organisationDefaultConfig = (
        await this.fetchOrganisationSettings("default")
      ).data.organization;
      // let enableDriverAnalysis = false;
      // console.log(
      //   organisationConfig,
      //   organisationDefaultConfig,
      //   "organisationDefaultConfig"
      // );

      this.setState({
        organisationConfig,
        organisationDefaultConfig,
      });
    } catch (error) {}
  }

  componentDidUpdate(prevProps, prevState) {
    let userDetails = this.getUserDetails();
    if (
      (this.userID && this.userID !== userDetails.userID)
    ) {
      let userID = (this.userID = userDetails.userID);

      this.email = userDetails.email;
      this.setState({
        userID: userID,
      });
    }

    console.log("[brand logo]: ", prevProps?.brandLogo, "this.props", this.props?.brandLogo);
    console.log("[copilot logo]: ", prevProps?.copilotIcon, "this.props", this.props?.copilotIcon);
    if (
      prevProps &&
      this.props.brandLogo &&
      prevProps.brandLogo !== this.props.brandLogo
    ) {
      console.log("[chat session id updated]: ", this.props.activeChatSession);
      this.setState(
        {
          brandLogo: this.props.brandLogo,
          copilotIcon: this.props.copilotIcon
        }
      );
    }
  }

  // keyDownHandler = event => {
  //   console.log('User pressed: ', event.key);

  //   if (event.key === 'Escape') {
  //     event.preventDefault();

  //     // 👇️ your logic here
  //     this.onCancelQuestion();
  //   }
  // };

  fetchProjectInfo = () => {
    configurationapiService.getProjectInfo(null)
      .promise
      .then(response => {
        const data = response.data?.data;
        this.props.setProjectInfo(data);
      });
  }


  fetchOrganisationSettings = (organizationId) => {
    return DocumentService.getOrgDoc(null, {
      orgId: organizationId,
    }).promise;
  };

  fetchTransactionList = () => {
    DataDiscoveryService.fetchTransactionList({
      userID: this.state.userID,
    }).promise.then((response) => {
      this.setState({
        transactionList: response.data,
      });
    });
  };

  setProjectTheme = async () => {
    let data;
    try {
      data = await ThemeService.getProjectTheme({
        organizationId: getCookie("organisation"),
        projectId: getCookie("projectID"),
        useParent: true
      }).promise;
    } catch(error) {
      console.log("[setProjectTheme] ERROR: ", error);
      // if(error.status === 404) {
      //   console.log("Project theme not set");
      //   try {
      //     data = await ThemeService.getOrganizationTheme({
      //       organizationId: getCookie("organisation"),
      //       useParent: true
      //     }).promise;
      //   } catch(error) {
      //     console.log("Organisation theme not set");
      //   }
      // }
    }

    const theme = data ? data.data : null;
    
    if(theme) {
      const logo =  theme.theme.logo;
      const copilotIcon = theme.theme.copilotIcon;
      console.log( "fetchLogo success theme");
      this.props
      .setThemeData(theme.theme)
      .then(() => {
        if(logo) {
            fetchImage(
              logo,
              (imgURL) => {
                this.setState({
                  brandLogo: imgURL
                });
        
                console.log(imgURL, "fetchLogo success");
              },
              (error) => {
                this.setState({
                  brandLogo: this.props.defaultBrandLogo
                });
                console.log(error, "fetchLogo error");
              }
            );
          } else {
            this.setState({
              brandLogo: this.props.defaultBrandLogo
            });
          }

          if(copilotIcon) {
            fetchImage(
              copilotIcon,
              (imgURL) => {
                this.setState({
                  copilotIcon: imgURL
                });
        
                console.log(imgURL, "fetch copilotIcon success");
              },
              (error) => {
                console.log(error, "fetch copilotIcon error");
              }
            );
          } else {
              this.setState({
                copilotIcon: copilotLogo
              });
          }
        })
    }

    this.setState({
      loading: false
    });


    console.log(theme, "projectTheme")

  };

  getUserDetails = () => {
    let userID = this.props.userDetails?.userID,
    email = this.props.userDetails?.email;

    return {
      email,
      userID
    };
  };

  fetchSystemSettings = () => {
    httpRequestService.getRequest(
      `${configurationapiURL}settings`,
      (data) => {
        console.log("Fetched configurations: ", data);
        let settings = data && data.settings;
        if (settings && settings.length) {
          let currencyFormat;
          let supportEmail;
          let autosuggestSettings = {};
          let enableAutoAnalysis = false;
          for (let i = 0; i < settings.length; i++) {
            if (settings[i].name === "DISPLAY_CURRENCY_FORMAT") {
              SessionService.setItem(
                "currencyFormat",
                JSON.stringify(settings[i].value)
              );
              currencyFormat = settings[i].value;
            } else if (settings[i].name === "SUPPORT_EMAIL") {
              supportEmail = settings[i];
            } else if (settings[i].name === "auto_suggest_enabled") {
              autosuggestSettings.enabled = settings[i].value;
            } else if (settings[i].name === "auto_suggest_interval") {
              autosuggestSettings.interval = settings[i].value;
            } else if (settings[i].name === "AUTO_ANALYSIS") {
              enableAutoAnalysis = settings[i].value;
            }
          }
          console.log("supportEmail: ", supportEmail);
          console.log("autosuggestSettings: ", autosuggestSettings);
          console.log("enableAutoAnalysis: ", enableAutoAnalysis);
          this.props.setSystemSettings({
            settings: data.settings,
            currencyFormat,
            supportEmail,
            autosuggestSettings,
            enableAutoAnalysis,
          });
        }
      },
      (error) => {
        console.log("Error fetching settings: ", error);
      }
    );
  };

  // loggoutHandler = (alertMessage) => {
  //   let loginResponse = SessionService.getItem(USER_LOGIN_RESPONSE);
  //   this.logout();

  //   // Handling no project assign case with message
  //   setTimeout(() => {
  //     SessionService.setItem(
  //       MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY,
  //       alertMessage
  //     );
  //     SessionService.setItem(USER_LOGIN_RESPONSE, loginResponse);
  //   }, 1000);
  // };

  // Cmmmon functions for navbar


  setWindowType = () => {
    if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      this.setState({
        deviceType: DEVICE_TYPES_BY_WIDTH.tablet,
      });
    } else if (window.innerWidth < 768) {
      this.setState({
        deviceType: DEVICE_TYPES_BY_WIDTH.mobile,
      });
    } else if (window.innerWidth >= 1024) {
      this.setState({
        deviceType: DEVICE_TYPES_BY_WIDTH.desktop,
      });
    }
  };

  resize = () => {
    clearInterval(this.timer);
    this.timer = setTimeout(this.setWindowType, 250);
  };

  // End of common functions

  // Profile Section functions

  handleClosePassModal = () => {
    this.setState({
      openPassModal: false,
    });
  };

  passwordChangeSuccess = () => {};

  handleCloseTimezoneModal = () => {
    this.setState({
      openTimezoneModal: false,
    });
  };

  toggleLoader = (lable) => {
    this.props.showLoaderCB(lable);
    setTimeout(() => {
      this.props.hideLoaderCB();
    }, 1000);
  };

  handleProfileOptionClick = (data) => {
    switch (data.selected.value) {
      case "logout":
        this.props.logout("user_init");
        break;
      case "chngPassword":
        this.setState({
          openPassModal: true,
          openTimezoneModal: false,
        });
        break;
      case "chngTimezone":
        this.setState({
          openTimezoneModal: true,
          openPassModal: false,
        });
        break;
    }
  };

  getSettingInfo = () => {
    let url = `${configurationapiURL}settings`;
    httpRequestService.getRequest(
      url,
      (data) => {
        const SUPPORT_EMAIL = "SUPPORT_EMAIL";
        let supportEmailObj = data.settings.filter(
          (item) => item.name === SUPPORT_EMAIL
        );
        if (supportEmailObj && supportEmailObj.length === 1) {
          this.setState({
            supportEmail: supportEmailObj[0],
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };


  // timezoneChangeSuccessHandler = () => {
  //   this.setState({
  //     openTimezoneModal: false,
  //   });

  //   this.props.setAppMessage({
  //     message: "Timezone changed successfully",
  //     type: "success",
  //   });
  // };

  // timezoneChangeErrorHandler = () => {
  //   this.setState({
  //     openTimezoneModal: false,
  //   });

  //   this.props.setAppMessage({
  //     message: "Error while changing timezone",
  //     type: "error",
  //   });
  // };

  // passwordChangeSuccessHandler = () => {
  //   this.setState({
  //     openPassModal: false,
  //   });

  //   this.props.setAppMessage({
  //     message: "Password changed successfully",
  //     type: "success",
  //   });
  // };

  // passwordChangeErrorHandler = (error) => {
  //   this.setState({
  //     openPassModal: false,
  //   });

  //   this.props.setAppMessage({
  //     message: error || "Error while changing password",
  //     type: "error",
  //   });
  // };


  getProfileMenuOptions = () => {
    const profileSectionOptions = [];
    // profileSectionOptions.push({
    //   title: "",
    //   options: actionMenuOptions,
    // });

    profileSectionOptions.push({
      options: logoutOption,
    });

    return profileSectionOptions;
  }

  render() {
    return (
      <AppContext.Consumer>
        {(value) => (
          <div
            className={
              "main-page-container"
            }>
            {!this.state.loading  ? (
              <MainLayout 
                deviceType={this.state.deviceType}
                brandLogo={this.props.brandLogo || this.state.brandLogo  } 
                copilotIcon={this.state.copilotIcon || this.props.copilotIcon}
                profileSection={{
                  profileSectionOptions: this.getProfileMenuOptions(),
                  handleProfileOptionClick: this.handleProfileOptionClick,
                  logout: this.props.logout,
                  ...this.props.userDetails,
                }}
                transactionList={this.state.transactionList}
              />
            ) : (
              <AppLoader></AppLoader>
            )}
          </div>
          
        )}
      </AppContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userProjects: state.userProjects.userProjects,
    currProject: state.currProject.currProject,
    userDetails: state.user.details
  };
};

const dispatchStateToProps = (dispatch) => {
  return {
    showLoaderFn: (label) => dispatch(showLoaderFn(label || "Loading")),
    hideLoaderFn: () => dispatch(hideLoaderFn()),
    setAppMessage: (item) => dispatch(setAppMessage(item)),
    setCurrentProject: (project) => dispatch(setCurrentProject(project)),
    setProjectList: (project) => dispatch(setProjectList(project)),
    setSystemSettings: (data) => dispatch(setSystemSettings(data)),
    setProjectInfo: (data) => dispatch(setProjectInfo(data)),
  };
};

export default connect(mapStateToProps, dispatchStateToProps)(Main);