import styled from "styled-components";
import { getStyleString } from "../../utils/styleUtils";

export const ChatContainer = styled.div`
    margin-bottom: 24px;
    position: relative;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 768px) and (max-width: 1024px) {
        padding-left: 80px;
        padding-right: 80px;
    }

    @media (min-width: 1024px) {
        ${props => props.showAvailableData ? `padding-left: 40px;padding-right: 40px;` : `padding-left: 140px;padding-right: 140px;`};
    }
    
  ${(props) => `
        &.user {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            background-color: ${props.theme["ui_05"]};

            .profile-icon svg {
                path {
                    fill: ${props.theme["icon_02"]};
                }
            }

            .button-icon {
                height: 36px;
                width: 36px;
            }
        }

        &.crux {
            grid-template-columns: 100%;
            display: grid;
            grid-column-gap: 8px;
            align-items: center;
            background-color: ${props.theme["ui_04"]};
            .profile-icon {
                padding: 4px 6px;
                border-radius: 50%;
                background-color:  ${props.theme["ui_01"]};
                box-sizing: border-box;
                display: flex;
                align-items: center;
                
                svg , img {
                    width: 100%;
                    max-height: 100%;
                    path {
                        fill: ${props.theme["icon02"]};
                    }
                    }
                }
            }
        }
    `}
`;

export const UserChatContainer  = styled.div`
  ${(props) => `
    grid-template-columns:  ${props.isEditable ? "36px calc(100% - 36px - 8px)" : "36px calc(100% - 36px - 8px - 36px - 44px) 72px"};
    display: grid;
    grid-column-gap: 8px;
    align-items: center;
    box-sizing: border-box;
  `}

  .edit-btn {
    position: relative;

    &.disabled {
        cursor: not-allowed;

        &:hover .chat__-info-tooltip {
            visibility: visible;
        }
    }


  }
}`;

export const MessageWrapper = styled.div`
    margin-top: 24px;
    width: 100%;
}`;

export const Chat = styled.div`
  ${(props) => `
        // position: relative;
    
        color: ${props.theme["text_02"].light};
        &.user {
            border-radius: 12px 12px 2px 12px;
            color: ${props.theme["text_01"].light};
            ${getStyleString(props.theme["text_question"])};
        }

        &.crux {
            border-radius: 12px 12px 12px 2px;

        }

        .response-content {
            &:first-child {
                margin-top: 0px!important;
            }

            &:only-child {
                margin-top: 0px!important;
            }
        }
        
        .edited-status {
            color: ${props.theme.text_04.light};
            margin-left: 4px;
            display: inline-block;
        }
    `}

    & ${MessageWrapper}:first-child {
        margin-top: 0px;
    }
`;

export const ProfileIcon = styled.div`
    ${(props) => `
        width: 36px;
        height: 36px;
        cursor: pointer;

    `}
}`;

export const DocumentsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    ${(props) => `


    `}
}`;

export const ResponseSection = styled.div`
    margin-top: 24px;

    &:first-child {
        margin-top: 0px;
    }
}`;

export const ResponseContent = styled.div`
    ${(props) => `
        margin-top: 24px;
        width: 100%;
        box-sizing: border-box;
        background-color: ${props.theme["ui_05"]};
        border-radius: 12px;
        padding: 20px;
        color: ${props.theme["text_02"].light};
    `}

    & ${ResponseSection}:first-child .carousel-no-title {
        margin-top: -20px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
}`;

export const ResponseSubSection = styled.div`
    ${(props) => `
        margin-bottom: 16px;
    `}
}`;

export const GeneratedByDiv = styled.div`
    ${(props) => `
        display: flex;
        margin-bottom: 12px;
        color: ${props.theme["text_03"].light};

        .text {
            margin-left: 4px;
        }
    `}
}`;

export const Time = styled.div`
    ${(props) => `
        color: ${props.theme["text_03"].light};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        left: 100%;
        bottom: 0px;
        width: max-content;
    `}
}`;

export const Loader = styled.div`
    margin-top: 24px;
`;

export const LoadingShimmer = styled.div`
    ${(props) => `
        
        position: relative;
        width: 100%;
     

        .shimmerBG {
            animation-duration: 2.2s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: shimmer;
            animation-timing-function: linear;
            // background: #ddd;
            // background: linear-gradient(97.67deg, rgba(233, 236, 242, 0.85) 0.07%, rgba(233, 236, 242, 0.34) 98.82%);
            background: #f6f7f9;
            background-image: linear-gradient(to right, rgba(233, 236, 242, 0.85) 0%, rgba(233, 236, 242, 0.34) 20%, rgba(233, 236, 242, 0.85) 40%, rgba(233, 236, 242, 0.85) 100%);
            background-size: 1200px 100%;
        }

        @-webkit-keyframes shimmer {
            0% {
                background-position: -100% 0;
            }
            100% {
                background-position: 100% 0;
            }
        }

        @keyframes shimmer {
            0% {
                background-position: -1200px 0;
            }
            100% {
                background-position: 1200px 0;
            }
        }
        
        .media {
            height: 273px;
        }
    `}
}`;

export const InitialLoader = styled.div``;

export const InitialLoaderAnimation = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Footer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-top: 14px;
`;

export const FeedbackContainer = styled.div`
    ${(props) => `
        margin-left: auto;
        // height: fit-content;
        position: relative;
        display: flex;
        // top: 8px;

        svg {
            display: list-item;
            width: 24px;
            height: 24px;
            cursor: pointer;
            path {
                fill: ${props.theme.icon02}
            }
        }

        .filled {
            svg {
                pointer-events: none;
                cursor: default;
                path {
                    fill: ${props.theme.iconInteractive}
                }
            }
        }
        
    `}
}`;

export const AnswerCardWrapper = styled.div`
    .ans-card {
        border: none;
    }

    .ans-card__header {
        padding: 0px 0px 12px 0px;
        border-bottom: 0px;
    }

    .ans-card__body {
        padding: 0px;
    }

    .ans-card__header-actions {
        right: 0px;
        top: 0px;
    }
}`;


export const EditDiv = styled.div`
}`;


export const EditInputDiv = styled.input`
    border-radius: 4px;
    padding: 8px;
    outline: none;
    background: transparent;
    width: 100%;
    box-sizing: border-box;

    ${(props) => `  
        border: 1px solid ${props.theme.line01};  
        ${getStyleString(props.theme["web_ui_02_regular"])};
    `}
}`;

export const ActionDiv = styled.div`
    margin-top: 12px;
    display: flex;
    justify-content: end;
}`;


export const InfoTooltip = styled.div`
  position: absolute;
  width: 120px;
  top: 100%;
  left: 10px;
  transform: translateX(-50%);
  visibility: hidden;
  pointer-events: none;
  z-index: 100;
`;


export const Conatiner = styled.div`
    position: relative;
    box-sizing: border-box;
    ${props => props.showOnboarding ? "height: 100%;" : ""}
   /* overflow: auto; */
`;

export const OnboardingContainer = styled.div`
  position: absolute;
  bottom: 0px;
`;
