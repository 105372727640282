import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import NewAskBar from "./new-ask-bar/NewAskBar";
import { useTheme } from 'styled-components';
import suggestionsHelper from "../../../../utils/suggestions-helper.js";
import SuggestionDropdown from "./suggestion-dropdown/SuggestionDropdown";
import {SVGIcon, Button, ActionMenu, Tooltip, SingleSelectMenu} from '@cuddle-dev/web-components';
import { SOURCES } from "../../../../constants";
import { useAppSelector, useAppDispatch } from '../../../../stores/redux-hooks';

//styled components
import {
  Container as StyledContainer,
  AskContainer as StyledAskContainer,
  Dropdown as StyledDropdown,
  SourceSelector as StyledSourceSelector,
  CTAs as StyledCTAs,
  Cancel as StyledCancel,
  Submit as StyledSubmit,
  SourceDropdown as StyledSourceDropdown,
  SourceTooltip as StyledSourceTooltip
} from "./styled-components";

//images
import { ReactComponent as DataIcon } from "../../../../images/data_20.svg";
import { ReactComponent as DocumentIcon } from "../../../../images/document_20.svg";
import { ReactComponent as ChevronDownIcon } from "../../../../images/chevron_down_20.svg";

//constants
// const SOURCES = {
//   data: "DATA",
//   document: "DOCUMENT"
// };
const suggestionsHelperObj = suggestionsHelper();

function AskBar(props) {
  const theme = useTheme();
  const askBarRef: MutableRefObject<HTMLDivElement> = useRef();
  const sourceRef: MutableRefObject<HTMLDivElement> = useRef();
  const [inputValue, setInputValue] = useState(props.question || "");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [inlineSuggestion, setInlineSuggestion] = useState("");
  const [source, setSource] = useState(props.selectedDataSource);
  const [highlightedSuggestion, setHighlightedSuggestion] = useState();
  const [showSourceDropdown, setShowSourceDropdown] = useState(false);
  const autosuggestSettings = useAppSelector((state) => state.settings.autosuggestSettings);
  const isAutoSuggestEnabled = (props.selectedDataSource === SOURCES.data) && autosuggestSettings?.enabled;

  useEffect(() => {
    const handleDocumentClick = function (event) {
      if (
        askBarRef &&
        askBarRef.current &&
        !askBarRef.current.contains(event.target)
      ) {
        if (showSuggestions) {
          setShowSuggestions(false);
        }
      }
    };

    document.addEventListener("click", handleDocumentClick, false);

    return function cleanup() {
      document.removeEventListener("click", handleDocumentClick, false);
    };
  });

  useEffect(() => {
    const handleDocumentClick = function (event) {
      if (
        sourceRef &&
        sourceRef.current &&
        !sourceRef.current.contains(event.target)
      ) {
        if (showSourceDropdown) {
          setShowSourceDropdown(false);
        }
      }
    };

    document.addEventListener("click", handleDocumentClick, false);

    return function cleanup() {
      document.removeEventListener("click", handleDocumentClick, false);
    };
  });

  useEffect(() => {
    // console.log("=====", highlightedSuggestion);
    if(typeof highlightedSuggestion === "number") {
      // console.log("=====", suggestions[highlightedSuggestion]);
      suggestionsClickHandler(suggestions[highlightedSuggestion], false, true);
    }
  }, [highlightedSuggestion]);

  useEffect(() => {
    if(props.clearAsk) {
      clearAskInput();
      if(props.onAskClear) {
        props.onAskClear();
      }
    }
  }, [props.clearAsk]);

  function askFocusHandler() {
    suggestionsHelperObj.startWebsocketConnection({
        websocketMessageHandler,
        userID: props.userID,
        onConnect: () => {
            // this.setState({ websocketConnected: true });
        },
        onDisconnect: (data) => {
            console.log("[startWebsocketConnection - onDisconnect] data: ", data);
            // this.setState({ websocketConnected: false });
        },
    });
  }

  function askBlurHandler() {
      // console.log("askBlurHandler");
      suggestionsHelperObj.closeWebsocketConnection();
  }

  function websocketMessageHandler(message) {
    if(message.suggestion && message.suggestion.length) {
      console.log("Suggestions: ", message.suggestion);
        // this.setState({
        //     suggestions: [],
        //     highlightedSuggestion: undefined,
        //     inlineSuggestion: undefined
        // }, () => {
            let suggestionsSubset = message.suggestion.slice(0, 3);
            let newSuggestions = suggestionsHelperObj.getSuggestions(suggestionsSubset);

            const firstSuggestion = newSuggestions && newSuggestions[0] && newSuggestions[0].originalData;
            const isSuggestionAtEnd = firstSuggestion && (firstSuggestion.offset + firstSuggestion.span.length) === message.question.length;
            let newInlineSuggestion;
            let firstSuggestionLabel;

            if(firstSuggestion) {
              if(firstSuggestion.category === "CURATED_ANALYSIS") {
                firstSuggestionLabel = firstSuggestion.text;
              } else {
                firstSuggestionLabel = firstSuggestion.label;
              }
            }

            if(firstSuggestionLabel && firstSuggestionLabel.toLowerCase().indexOf(firstSuggestion.span.toLowerCase()) === 0 && isSuggestionAtEnd) {
              newInlineSuggestion = firstSuggestionLabel.slice(firstSuggestion.span.length);
                newSuggestions[0].isAutoCompleted = true;
            }

            if(!props.bottomDropdown) {
              newSuggestions.reverse();
            }

            setSuggestions(newSuggestions);
            setInlineSuggestion(newInlineSuggestion);
        // });
    } else {
      setSuggestions([]);
      setInlineSuggestion(undefined);
      setHighlightedSuggestion(undefined);
      suggestionsHelperObj.clearTemporarySelection(true);
        // this.setState({
        //     suggestions: [],
        //     highlightedSuggestion: undefined,
        //     inlineSuggestion: undefined
        // });
    }
  }

  function askInputChangeHandler(event) {
    // console.log("[askInputChangeHandler] event: ", event);
    const inputQuestion = event.target.value;
    // const isAutoSuggestEnabled = autosuggestSettings?.enabled;
    const showSuggestions = isAutoSuggestEnabled && inputQuestion && inputQuestion.length > 2 ? true : false;

    setInputValue(inputQuestion);
    setShowSuggestions(showSuggestions);
    setSuggestions([]);
    setInlineSuggestion(undefined);
    setHighlightedSuggestion(undefined);
    
    anlyticsEventBeforeClear();
    suggestionsHelperObj.clearTemporarySelection(true);

    if(isAutoSuggestEnabled) {
        // console.log("[askInputChangeHandler] event: ", event);
        let activeWords = suggestionsHelperObj.cursorChanged(event.target);

        if(showSuggestions) {
            suggestionsHelperObj.sendWebsocketRequest(inputQuestion, activeWords);
        }
    }
  }

  function askKeyDownHandler(event) {
    // console.log("event.key", event, event.key, event.key === "Tab", inputValue);
        
    if(event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
    }
    const questionText = event.target.value;
    const currentTarget = event.currentTarget;

    suggestionsHelperObj.setStartCursor(currentTarget.selectionStart);
    suggestionsHelperObj.setEndCursor(currentTarget.selectionEnd);

    // console.log("[askKeyDownHandler] currentTarget: ", event.currentTarget.selectionStart, event.currentTarget.selectionEnd);
    // console.log("this.state.highlightedSuggestion: ", this.state.highlightedSuggestion);
    if(event.key === 'Enter') {
        // if(typeof highlightedSuggestion === "number") {
        //     this.suggestionsClickHandler(this.state.suggestions[this.state.highlightedSuggestion]);
        // } else 
        if(questionText && props.onAsk) {
          // console.log("suggestionsHelperObj.getResolvedEntities(): ", suggestionsHelperObj.getResolvedEntities(), questionText);
          props.onAsk(questionText, {
            from: "Question Bar",
            method: "Enter Key"
          }, suggestionsHelperObj.getResolvedEntities(), props.hideSource ? null : source);
          
          anlyticsEventBeforeClear();
          clearAskInput();
          // suggestionsHelperObj.clearTemporarySelection();
        }
    } else if(event.key === "ArrowRight" && inlineSuggestion) {
        event.preventDefault();
        sendAutosuggestAnalyticsEvent(suggestions[suggestions.length - 1], true, false);
        suggestionsClickHandler(suggestions[suggestions.length - 1], true, false);
        return
    }
  }

  function highlightSuggestion(key) {
    if(suggestions && suggestions.length) {
      let newHighlightedSuggestion;
      // console.log("newHighlightedSuggestion:: ", newHighlightedSuggestion);
      if(key === "ArrowDown") {
          if(highlightedSuggestion === undefined) {
            newHighlightedSuggestion = 0;
          } else if(highlightedSuggestion === (suggestions.length - 1)) {
            newHighlightedSuggestion = undefined;
            const questionText = suggestionsHelperObj.clearTemporarySelection();
            // console.log("questionText: ", questionText);
            setTextForInput(questionText);
          } else {
            newHighlightedSuggestion = highlightedSuggestion;
            newHighlightedSuggestion += 1;
          }
          setHighlightedSuggestion(newHighlightedSuggestion);
      } else if(key === "ArrowUp") {
          if(highlightedSuggestion === undefined) {
            newHighlightedSuggestion = suggestions.length - 1;
          } else if(highlightedSuggestion === 0) {
            newHighlightedSuggestion = undefined;
            const questionText = suggestionsHelperObj.clearTemporarySelection();
            // console.log("questionText: ", questionText);
            setTextForInput(questionText);
          } else {
            newHighlightedSuggestion = highlightedSuggestion;
            newHighlightedSuggestion -= 1;
          }
          setHighlightedSuggestion(newHighlightedSuggestion);
      }
    }
  }

  function setTextForInput(text) {
    if(text) {
      setInputValue(text);

      if(askBarRef && askBarRef.current) {
        // console.log("focus ask bar", newCursorPos);
        let askInput = askBarRef.current.querySelector("input");

        if(askInput) {
            askInput.focus();
            setTimeout(() => {
              askInput.setSelectionRange(suggestionsHelperObj.getStartCursor(), suggestionsHelperObj.getEndCursor());
              askInput.scrollLeft = askInput.scrollWidth;
            }, 0);
        }
      }
    }
  }

  function askKeyUpHandler(event) {
    // console.log("askKeyUpHandler");
    suggestionsHelperObj.cursorChanged(event.target);
    // console.log("event key: ", event.key); //ArrowDown ArrowUp
    highlightSuggestion(event.key);
  }

  function clearAskInput() {
    // console.log("clearAskInput");
    suggestionsHelperObj.reset();
    setInputValue("");
    setShowSuggestions(false);
    setSuggestions([]);
    setHighlightedSuggestion(undefined);
    setInlineSuggestion(undefined);
    askBarRef.current.querySelector("input").blur();
  }

  function askClickHandler(event) {
    suggestionsHelperObj.cursorChanged(event.target);
  }

  function anlyticsEventBeforeClear() {
    const temporarySelection = suggestionsHelperObj.getTemporarySelection();
    
    if(temporarySelection) {
      sendAutosuggestAnalyticsEvent(temporarySelection.suggestionData, false, false);
    }
  }

  function sendAutosuggestAnalyticsEvent(data, isSmartCompose, isClick) {
    // console.log("[sendAutosuggestAnalyticsEvent] inlineSuggestion: ", inlineSuggestion);
    // console.log("[sendAutosuggestAnalyticsEvent] suggestions: ", suggestions);
    // console.log("[sendAutosuggestAnalyticsEvent] data: ", data);
    const analyticsData = {
      Type: data.originalData.category,
      From: isSmartCompose ? "Smart Compose" : "Suggestions",
      "Smart Compose": !!data.isAutoCompleted,
      "Method": isClick ? "Click" : "Keyboard Shortcut"
    };
  }

  function suggestionsClickHandler(data, isAutoComplete, isTemporary) {
    // console.log("suggestionsClickHandler(data, isAutoComplete, isTemporary)", data, isAutoComplete, isTemporary);
    const newText = suggestionsHelperObj.resolveSuggestion(data, inputValue, isTemporary);

    setInputValue(newText);

    if(!isTemporary) {
      sendAutosuggestAnalyticsEvent(data, false, true);
      setSuggestions([]);
      setHighlightedSuggestion(undefined);
      setInlineSuggestion(undefined);
      suggestionsHelperObj.clearTemporarySelection(true);

      if(askBarRef && askBarRef.current) {
          // console.log("focus ask bar", newCursorPos);
          let askInput = askBarRef.current.querySelector("input");

          if(askInput) {
              askInput.focus();
              
              setTimeout(() => {
                  askInput.setSelectionRange(suggestionsHelperObj.getStartCursor(), suggestionsHelperObj.getEndCursor());
                  askInput.scrollLeft = askInput.scrollWidth;
                  
                  const resolvedEntities = suggestionsHelperObj.getResolvedEntities();

                  if(resolvedEntities && resolvedEntities.length === 1 && resolvedEntities[0].originalData.category === "CURATED_ANALYSIS" && resolvedEntities[0].originalData.type === "ALL_BO_OF_ATTRIBUTE") {
                      // console.log("[suggestionsClickHandler] Auto open dropdown");
                      setTimeout(() => {
                          let activeWords = suggestionsHelperObj.cursorChanged(askInput);
                          
                          suggestionsHelperObj.sendWebsocketRequest(inputValue, activeWords);

                          setShowSuggestions(true);
                      }, 500);
                  }
              }, 0);
          }
      } 
    } else {
      if(askBarRef && askBarRef.current) {
        // console.log("focus ask bar", newCursorPos);
        let askInput = askBarRef.current.querySelector("input");

        if(askInput) {
            askInput.focus();
            setTimeout(() => {
              askInput.setSelectionRange(suggestionsHelperObj.getStartCursor(), suggestionsHelperObj.getEndCursor());
              askInput.scrollLeft = askInput.scrollWidth;
            }, 0);
        }
      }         
    }
  }

  function askSendClickHandler(questionText) {
    // console.log("askSendClickHandler questionText:", questionText);
    // if(typeof highlightedSuggestion === "number") {
    //     // this.suggestionsClickHandler(this.state.suggestions[this.state.highlightedSuggestion]);
    // } else 
    if(questionText && props.onAsk) {
        // console.log("questionText onAsk inside", questionText);
        
        props.onAsk(questionText, {
          from: "Question Bar",
          method: "Button"
        }, suggestionsHelperObj.getResolvedEntities(), props.hideSource ? null : source);

        anlyticsEventBeforeClear();
        clearAskInput();
        // suggestionsHelperObj.clearTemporarySelection();
    }
  }

  function submitClickHandler() {
    askSendClickHandler(inputValue);
  }

  function sourceIconClickHandler() {
    setShowSourceDropdown(!showSourceDropdown);
  }

  // function sourceClickHandler(data) {
  //   console.log("[sourceClickHandler] data: ", data);
  //   if(source !== SOURCES.data && data?.selected?.value === SOURCES.data) {
  //     setSource(SOURCES.data);
  //     props.sourceChangeHandler(SOURCES.data);
  //   } else if(source !== SOURCES.document && data?.selected?.value === SOURCES.document) {
  //     setSource(SOURCES.document);
  //     props.sourceChangeHandler(SOURCES.data);
  //   }
  // }


  const resolvedEntities = suggestionsHelperObj.getResolvedEntities();
  let banner;

  // if(resolvedEntities && 
  //   ((resolvedEntities.length === 1 && typeof highlightedSuggestion !== "number") ||
  //   (resolvedEntities.length === 2 && typeof highlightedSuggestion === "number")) &&
  //   resolvedEntities[0].originalData.category === "CURATED_ANALYSIS" && 
  //   resolvedEntities[0].originalData.type === "ALL_BO_OF_ATTRIBUTE"
  // ) {
  //   banner = {
  //     text: "Please select one of the following suggestions to proceed"
  //   };
  // }

  return (
    <StyledContainer className="ask-bar">
      <StyledAskContainer hideSource={props.hideSource}>
        {!props.hideSource ? 
          <StyledSourceSelector onClick={sourceIconClickHandler} ref={sourceRef} >
            <SVGIcon Icon={props.selectedDataSource === SOURCES.document ? DocumentIcon : DataIcon} width={24} height={24} fill={theme["icon_01"].light}/>
            <SVGIcon Icon={ChevronDownIcon} width={20} height={20} fill={theme["icon_03"].light}/>
            {showSourceDropdown ?
              <StyledSourceDropdown isdisabled={!props.selectedDataSource}>
                <SingleSelectMenu 
                  type="radio"
                  selected={props.selectedDataSource}
                  options={[
                    {
                        label: "Data",
                        value: SOURCES.data,
                        labelIcon: DataIcon,
                    },
                    {
                        label: "Documents",
                        value: SOURCES.document,
                        labelIcon: DocumentIcon,
                    },
                  ]}
                  changeHandler={props.sourceClickHandler}
                  links={null}
                />
              </StyledSourceDropdown>
              : (
              <StyledSourceTooltip>
                <Tooltip style={{position: "relative"}} 
                  text={`Source set to ${props.selectedDataSource === SOURCES.document ? "Documents" : "Data"}`}
                />
              </StyledSourceTooltip>
            )
            }
          </StyledSourceSelector>
          : null
        }
        <div ref={askBarRef}>
          <NewAskBar
            onKeyDown={askKeyDownHandler}
            onKeyUp={isAutoSuggestEnabled ? askKeyUpHandler : null}
            onChange={askInputChangeHandler}
            clearAskInput={clearAskInput}
            value={inputValue}
            onFocus={isAutoSuggestEnabled ? askFocusHandler : null}
            onBlur={isAutoSuggestEnabled ? askBlurHandler : null}
            onClick={isAutoSuggestEnabled ? askClickHandler : null}
            suggestionText={typeof highlightedSuggestion !== "number" && inlineSuggestion}
            onAsk={askSendClickHandler}
            disabled={props.disabled}
            placeholder={props.placeholder}
            isDropdownOpen={showSuggestions}
            customStyle={""}
            hideSend={props.submitUsingButton}
          />
        </div>
      </StyledAskContainer>
      {props.submitUsingButton ?
        <StyledCTAs>
          <StyledCancel>
            <Button
              buttonType={"link"}
              onClick={props.cancel}
              text={"Cancel"}
            />
          </StyledCancel>
          <StyledSubmit>
            <Button
              buttonType={"primary"}
              onClick={submitClickHandler}
              text={"Save & Submit"}
              disabled={props.disabled || !inputValue || (inputValue && inputValue === props.question)}
            />
          </StyledSubmit>
        </StyledCTAs>
        : null
      }
      {showSuggestions ? 
        <StyledDropdown bottomDropdown={props.bottomDropdown}>
          <SuggestionDropdown options={suggestions} 
            highlightedSuggestion={highlightedSuggestion}
            onChange={suggestionsClickHandler}
            banner={banner}
          />
        </StyledDropdown>
        : null
      }
      {/*
        <StyledDropdown>
          <DataAvailableDropdown theme={props.theme} />
        </StyledDropdown>
    */}
    </StyledContainer>
  );
}

export default AskBar;
